import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import { Stack, Typography, Pagination, useMediaQuery } from '@mui/material';

import Style from './style';
import Loader from '../../../common/Loader';
import FreeAttemptsUsedPopup from './FreeAttemptsUsed';
import { formatDate, isEmpty } from '../../../helpers/common';
import { ANSWER_SCORE_TEXT } from '../../../helpers/constants';
import { InfoIcon, PlayCircleOutlinedIcon } from '../../../assets/constants';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function Listing() {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const [outOfCredits, setOutOfCredits] = useState(false);
  const [page, setPage] = useState(1);

  const {
    getInterviews,
    interviews,
    getCurrentAnswer,
    isLoadingInterviews,
    interviewsCount,
    setToggle,
    setProgress,
    setStartInterview,
    setResultId,
    handleShare,
  } = useContext(CareerCoachInterviewContext);

  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = async (event) => {
      window.removeEventListener('popstate', handlePopState);
      localStorage.setItem('answerId', '');
      // navigate('/career-coach/dashboard', { replace: true });
      // window.location.reload();
    };
    // Listen for the popstate event, triggered by back button
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener when the component unmounts
  }, [navigate]);

  useEffect(() => {
    getInterviewsListing({ start: page - 1 });
  }, []);

  const rowsPerPage = 5;

  const getInterviewsListing = async (start) => {
    await getInterviews({ start: start * rowsPerPage, limit: rowsPerPage });
  };

  const onDataPageChange = (event, page) => {
    setPage(page);
    getInterviewsListing(page - 1);
  };
  const handleEvalution = (answerId) => {
    navigate(`/career-coach/evaluation?aw=${answerId}`, { replace: true });
  };

  return (
    <Stack
      px={smMediaquery ? 0 : 2}
      mt={'27px'}
      sx={{
        '& .box-list': {
          borderRadius: '6px',
          '&:nth-of-type(even)': {
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
          },
          '&:nth-of-type(odd)': {
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
          },
        },
      }}
    >
      {isLoadingInterviews ? (
        <Loader />
      ) : (
        <>
          {interviews?.map((interview, index) => {
            const highScore =
              interview?.scoreText?.toUpperCase() ===
              ANSWER_SCORE_TEXT.HIGHLY_RELEVANT;

            const mediumScore =
              interview?.scoreText?.toUpperCase() ===
              ANSWER_SCORE_TEXT.MODERATE;

            return (
              <Stack
                sx={{ backgroundColor: '#fff' }}
                my={0.5}
                p={2.2}
                key={interview?.id}
                className='box-list'
              >
                <Stack
                  direction='row'
                  width='100%'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Typography
                    variant='title16Bold'
                    sx={{
                      fontWeight: '600',
                      cursor: 'pointer',
                      maxWidth: 'calc(100% - 35px)',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={() => handleEvalution(interview?.answerId)}
                  >
                    {interview?.question?.question}
                  </Typography>
                  {interview?.contest === 'Y' ? (
                    <PlayCircleOutlinedIcon
                      onClick={() => {
                        localStorage.removeItem('answerId');
                        window.location.href =
                          interview?.contestData?.permalink;
                      }}
                      style={{ height: '25px', width: '25px' }}
                    />
                  ) : null}
                </Stack>

                <Typography
                  pt={0.5}
                  variant='title12Bold'
                  sx={{ fontWeight: '400', color: '#585858' }}
                >
                  Publish: {formatDate(interview.created_at)}
                  {interview?.contest === 'Y' && (
                    <span style={{ color: '#252424' }}>
                      <span style={{ fontSize: '13px' }}> &nbsp;•&nbsp; </span>{' '}
                      {interview.votes}{' '}
                      {interview.votes === 0 || interview.votes === '1'
                        ? 'Vote'
                        : 'Votes'}
                      {interview.votes > 0 && (
                        <span>
                          {' '}
                          <span style={{ fontSize: '13px' }}>
                            {' '}
                            &nbsp;•&nbsp;{' '}
                          </span>{' '}
                          {interview.rating} Rating
                        </span>
                      )}
                    </span>
                  )}
                </Typography>

                <Stack
                  width='100%'
                  mt='18px'
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      minWidth: '32%',
                      height: '37px',
                      borderRadius: '43px',
                      backgroundColor: highScore
                        ? 'rgba(10, 160, 106, 0.14)'
                        : mediumScore
                        ? 'rgba(232, 176, 41, 0.14)'
                        : 'rgba(222, 17, 53, 0.14)',
                    }}
                  >
                    <Typography
                      sx={{
                        color: highScore
                          ? '#0aa06a'
                          : mediumScore
                          ? '#e8b029'
                          : '#de1135',
                      }}
                      variant='title10Bold'
                    >
                      {highScore
                        ? 'Shabash'
                        : mediumScore
                        ? 'Mazah nhi aya!'
                        : 'Bura haal!'}
                    </Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      minWidth: '32%',
                      width:
                        Number(interview?.answerRetry) >=
                        Number(interview?.allowedAttempts)
                          ? 'calc(100% - 89px)'
                          : 'max-content',
                      maxWidth:
                        Number(interview?.answerRetry) >=
                        Number(interview?.allowedAttempts)
                          ? 'calc(100% - 34%)'
                          : '32%',
                      height: '37px',
                      border: '1px solid #d3d3d3',
                      borderRadius: '43px',
                    }}
                  >
                    <Typography variant='title10Bold'>
                      Attempts:{' '}
                      {`${interview?.answerRetry}/${interview?.allowedAttempts}`}
                    </Typography>{' '}
                    {Number(interview?.answerRetry) >=
                    Number(interview?.allowedAttempts) ? (
                      <InfoIcon
                        onClick={() => {
                          setOutOfCredits(true);
                        }}
                        style={{
                          height: '16px',
                          width: '16px',
                          marginLeft: '0.5rem',
                        }}
                      />
                    ) : null}
                  </Stack>
                  {Number(interview?.answerRetry) >=
                  Number(interview?.allowedAttempts) ? null : (
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        minWidth: '32%',
                        width: 'max-content',
                        maxWidth: '32%',
                        height: '37px',
                        border: '1px solid #d3d3d3',
                        borderRadius: '43px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (!isEmpty(interview?.testCode)) {
                          setProgress(0);
                          setResultId(false);
                          setStartInterview(true);
                          setToggle(true);
                          localStorage.setItem('answerId', interview?.answerId);
                          localStorage.setItem(
                            'careerCoachInterview',
                            interview?.testCode,
                          );
                          getCurrentAnswer().then(() => {
                            navigate(
                              `/career-coach/interview/${interview?.testCode}`,
                            );
                          });
                        }
                      }}
                    >
                      <Typography variant='title10Bold'>
                        {'Try Again'}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            );
          })}
          {isLoadingInterviews
            ? null
            : Math.ceil(interviewsCount / rowsPerPage) > 1 &&
              Number(interviewsCount ?? 0) > 5 && (
                <Stack
                  py={2}
                  direction='row'
                  justifyContent={smMediaquery ? 'center' : 'flex-end'}
                  alignItems='flex-end'
                >
                  <Pagination
                    count={Math.ceil(interviewsCount / rowsPerPage)}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    onChange={onDataPageChange}
                    sx={{ position: 'relative', right: '1rem' }}
                  />
                </Stack>
              )}
        </>
      )}

      <FreeAttemptsUsedPopup
        toggle={outOfCredits}
        setToggle={setOutOfCredits}
      />
    </Stack>
  );
}
