import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { Drawer, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';

import PaymentPopup from './PaymentPopup';
import { CartContext } from '../../Packages/Cart/CartContext';
import { useLogPayment } from '../../../services/useLogPayment';
import { useGetPayments } from '../../../services/useGetPayments';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function Payment({ toggle, setPaymentToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const { logPayment } = useLogPayment();

  const { getPayments, payments } = useGetPayments();
  useEffect(() => {
    getPayments();
  }, []);

  const { guruOrder, payNow } = useContext(CareerCoachInterviewContext);

  return (
    <Drawer
      anchor='bottom'
      open={toggle}
      className={classes.payment__dialog}
      sx={{
        '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
      }}
    >
      <Stack
        color='primary.light'
        pt={3}
        pb={7}
        px={1.8}
        direction='column'
        height='100%'
        width='100%'
        justifyContent='flex-start'
        alignItems='center'
        sx={{ height: '100%', overflowY: 'auto' }}
      >
        <Stack>
          <Typography
            pt={4}
            pb={10}
            variant='title26Bold'
            sx={{ fontWeight: '700', textAlign: 'center', lineHeight: '28px' }}
          >
            Upgrade to our premium membership to enjoy more questions, attempts,
            and AI coaching.
          </Typography>
          <Stack width='100%' spacing={2}>
            {payments?.map((k, i) => {
              return (
                <Stack
                  key={k?.amount + i}
                  onClick={() => {
                    logPayment({
                      paymentId: k?.id,
                      action: 'payment',
                    });
                    // setUpgradePopup(k?.id);
                    guruOrder(k?.id)
                    .then((resp) => {
                      payNow(resp?.data?.orderId, resp?.data?.amount);
                    });
                    // navigate('/career-coach/success');
                  }}
                  p={3}
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    borderRadius: '11px',
                    border: i === 0 ? '1px solid #fff' : '1px solid #0aa06a',
                  }}
                >
                  <Typography
                    pb={0.7}
                    variant='title24Bold'
                    sx={{ fontWeight: '800' }}
                  >
                    Rs. {k?.amount}
                  </Typography>
                  <Typography
                    variant='title16Bold'
                    sx={{ fontWeight: '500', textAlign: 'center' }}
                  >
                    {k?.questionsCount} questions - {k?.retry} attempts per
                    question
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          <Typography
            mt={5}
            variant='title14Bold'
            sx={{ fontWeight: '400', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
              logPayment({
                action: 'nothankyou',
              });
              setPaymentToggle(false);
            }}
          >
            No Thanks
          </Typography>
        </Stack>
        <PaymentPopup />
      </Stack>
    </Drawer>
  );
}
