const Style = (theme) => ({
  side__bar: {
    width: '325px',
    backgroundColor: 'var(--color-primary)',
    padding: '20px 30px 20px 30px',
    borderRight: '1px solid #E9F1FD',
    zIndex: 99,
    '& .MuiAvatar-root': {
      border: '0',
      fontSize: '30px',
      fontWeight: '700',
      height: '100px',
      width: '100px',
      background: '#2b2b2b',
      color: '#fff',
      marginTop: '40px',
      [theme.breakpoints.down('1380')]: {
        height: '70px',
        width: '70px',
        fontSize: '22px',
      },
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px',
        fontSize: '14px',
        marginTop: '0',
      },
    },
    [theme.breakpoints.down('md')]: {
      height: 'max-content',
      width: '100%',
      padding: '20px 24px 20px 24px',
    },
  },

  indicator: {
    right: '0px',
    backgroundColor: '#2b2b2b !important',
    height: '52px !important',
    width: '100% !important',
    borderRadius: '10px',
    [theme.breakpoints.down('1380')]: {
      height: '45px !important',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  tabs__bar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0',
    zIndex: '1',
    position: 'relative',
    borderRadius: '0',
    height: 'calc(100% - 460px)',
    '& .MuiTabScrollButton-root': {
      color: '#fff',
      borderBottom: '0',
      opacity: '1 !important',
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      color: 'rgba(109, 125, 139, .3)',
    },
    '& .MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'flex-start',
        height: '100%',
        '& .MuiTab-root': {
          margin: '0',
          opacity: '1',
          width: '100%',
          minWidth: '100%',
          maxWidth: '100%',
          fontSize: '14px',
          fontWeight: '400',
          minHeight: '52px',
          borderBottom: '0',
          borderRadius: '10px',
          padding: '.8rem 1.1rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          color: '#fff',
          '& > .MuiTab-iconWrapper': {
            marginRight: '14px',
          },
          [theme.breakpoints.down('1380')]: {
            minHeight: '45px',
            fontSize: '14px',
            fontWeight: '600',
          },
          [theme.breakpoints.down('md')]: {
            width: 'max-content',
            minWidth: 'max-content',
            maxWidth: 'max-content',
            fontSize: '12px',
            minHeight: '37px',
            padding: '.5rem .9rem',
          },
        },
        '& .Mui-selected': {
          zIndex: 1,
          [theme.breakpoints.down('md')]: {
            color: '#010103',
            backgroundColor: '#fff !important',
          },
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'row',
          overflowX: 'auto',
        },
      },
    },
    [theme.breakpoints.down('1380')]: {
      height: 'calc(100% - 410px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'max-content',
    },
  },
  logout__tab: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    fontSize: '13px',
    minHeight: '60px',
    borderBottom: '0',
    borderRadius: '10px',
    padding: '.8rem 1.1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    color: '#fff',
    cursor: 'pointer',
    [theme.breakpoints.down('1380')]: {
      minHeight: '50px',
      fontSize: '16px',
    },
  },
  drawer__menubar: {
    width: '100%',
    background: '#fff',
    '& .MuiDrawer-paper': {
      width: '100vw',
      backgroundColor: '#F7F7F7',
    },
    '& .MuiModal-backdrop': {
      display: 'none',
    },
  },
});
export default Style;
