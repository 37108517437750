import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';

import Style from './style';
import { SITE_AT } from '../../../helpers/utils';
import { APP_ENV } from '../../../helpers/constants';
import { useGetTypes } from '../services/useGetTypes';
import { useAuthContext } from '../../../Auth/useAuthContext';
import { useGetInterviews } from '../services/useGetInterviews';
import { useWebRTCRecorder } from '../../../helpers/hooks/useWebRTCRecorder';
import { useClearVideoInterview } from '../../../services/useClearVideoInterview';
import { useCompleteVideoInterview } from '../../../services/useCompleteVideoInterview';
import { useGetVideoInterviewQuestions } from '../../../services/useGetVideoInterviewQuestions';

import {
  isEmpty,
  setLocalStorage,
  getLocalStorage,
  convertMbsToBytes,
  getCurrentFormattedDate,
  generateRandomIntegerString,
} from '../../../helpers/common';
import {
  headers,
  usefetch,
  defaultHeaders,
  usefileUploader,
  fileUploadTokenHeaders,
} from '../../../helpers/fetch';

const Styles = makeStyles((theme) => Style(theme));
export const useCareerCoachInterview = () => {
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [id, setId] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [stream, setStream] = useState(false);
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [OrderId, setOrderId] = useState(null);
  const [Invoice, setInvoice] = useState(null);
  const [videoData, setVideoData] = useState({});
  const [selectedQType, setQType] = useState('');
  const [answerRd, setAnswerRd] = useState(null);
  const [answerId, setAnswerId] = useState(false);
  const [resultId, setResultId] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [paymentRes, setPaymentRes] = useState(null);
  const [language, setLanguage] = useState('english');
  const [guruFlow, setGuruFlow] = useState(false);
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const [loadingMore, setLoadingMore] = useState(null);
  const [PaymentData, setPaymentData] = useState(null);
  const [OrderPayment, setOrderAmount] = useState(null);
  const [progressBar, setProgressBar] = useState(false);
  const [startRecord, setStartRecord] = useState(false);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);
  const [questionTime, setQuestionTime] = useState(null);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [startInterview, setStartInterview] = useState(0);
  const [simpleLoader, setSimpleLoader] = useState(false);
  const [hideQuestion, setHideQuestion] = useState(false);
  const [selectedQuestions, setTQuestion] = useState(null);
  const [videoAnalysis, setVideoAnalysis] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [paymentDrawer, setPaymentDrawer] = useState(false);
  const [qualifyDrawer, setQualifyDrawer] = useState(false);
  const [resetRecorder, setResetRecorder] = useState(false);
  const [paymentSuccessToggle, setPaymenttSuccessToggle] = useState(false);
  const [questionDrawer, setQuestionDrawer] = useState(true);
  const [displayName, setDisplayNameCheck] = useState(false);
  const [showAgainVideo, setShowAgainVideo] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isSavingAnswers, setIsSavingAnswers] = useState(false);
  const [evaluationDrawer, setEvaluationDrawer] = useState(false);
  const [currentChunkUploded, setCurrentChunkUploded] = useState(0);
  const [completeProfileDrawer, setCompleteProfileDrawer] = useState(false);

  const { user } = useAuthContext();
  const { completeVideoInterview } = useCompleteVideoInterview();
  const { clearVideoInterview, isClearingInterview } = useClearVideoInterview();
  const intervalRef = useRef(null); // Store interval ID here

  const {
    creditsUsed,
    isLoadingTypes,
    interviewTypes,
    remainingCredits,
    getInterviewsTypes,
  } = useGetTypes();

  const {
    interviews,
    getInterviews,
    answerTestCode,
    interviewsCount,
    isLoadingInterviews,
  } = useGetInterviews();

  const {
    jobTitle,
    reRecord,
    isLoading,
    countdown,
    setRerecord,
    setIsLoading,
    setCountdown,
    isGettingQuestions,
    videoInterviewQuestions,
    isVideoInterviewCompleted,
    getVideoInterviewQuestions,
    setIsVideoInterviewCompleted,
  } = useGetVideoInterviewQuestions();

  const handleDataAvailable = async (blob) => {
    if (blob) {
      console.log('blob', blob);
      await handleSaveQuestion(blob, 'Y');
    }
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  let sliceSize = 3000;
  if (isIOS || isSafari) {
    sliceSize = 2000;
  }

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    getMediaStream,
    clearBlobUrl,
    error,
    mediaFormat,
    mediaStreamRef,
    stopAll,
  } = useWebRTCRecorder({
    video: true,
    stopStreamsOnStop: true,
    facingMode: { exact: 'user' },
    onDataAvailable: handleDataAvailable,
    timeSlice: sliceSize, // Record in seconds chunks
  });
  const videoRef = useRef();

  const getCurrentAnswer = () => {
    const cachedAnsId = localStorage.getItem('answerId');
    if (cachedAnsId && cachedAnsId !== 'undefined' && cachedAnsId !== 'null') {
      setLoader(true);
      return usefetch('videoInterview/ansStatus', 'post', defaultHeaders(), {
        id: cachedAnsId,
      }).then(function (resp) {
        setLoader(false);
        if (resp?.data?.question) {
          setVideoAnalysis(resp?.data?.analysis);
          setVideoData(resp?.data);
          if (resp?.data?.countdown) {
            setCountdown(resp?.data?.countdown);
          }
          if (resp?.data?.countdown) {
            setRerecord(resp?.data?.reRecord);
          }

          if (!isEmpty(resp?.data?.ans_lang)) {
            setLanguage(resp?.data?.ans_lang);
          }

          if (
            resp?.data?.answer_retry &&
            Number(resp?.data?.answer_retry) >= 9
          ) {
            setPaymentDrawer(true);
          } else {
            setResultId(false);
            setCurrentQuestion(resp?.data?.question);
            setActiveStep(resp?.data?.question.id);
            setQType(resp?.data?.question?.type);
            setQuestionDrawer(false);
          }
          setLoader(false);
          releaseDevices();
          if (SITE_AT === APP_ENV.LIVE || SITE_AT === APP_ENV.DEMO) {
            navigate('/career-coach/evaluation', { replace: true });
          }
        }

        return resp;
      });
    }
  };

  useEffect(() => {
    getCurrentAnswer();
  }, []);

  useEffect(() => {
    if (previewStream && videoRef.current) {
      videoRef.current.srcObject = previewStream;
    }
    if (previewStream) {
      setHasPermission(true);
      setIsLoading(false);
      gotUserMedia(previewStream);
    } else {
      setHasPermission(false);
      setIsLoading(false);
    }
  }, [previewStream]);

  useEffect(() => {
    if (error === 'recorder_error') {
      console.error(
        'from Error MediaRecorder Not supported on this browser try on an other device!',
      );
      usefetch('videoInterview/logIssues', 'post', defaultHeaders(), {
        testCode: id,
      }).then(function (resp) {});
      setSimpleLoader(true);
      alert(
        'MediaRecorder Not supported on this browser try on an other device!',
      );
    }
    // if (error) {
    //   alert(error);
    // }
  }, [error]);

  useEffect(() => {
    // console.log("selectedQType",selectedQType,"currentQuestion",currentQuestion,"activeStep",activeStep)
    if (selectedQType && id) {
      if (isEmpty(currentQuestion?.question)) {
        getVideoInterviewQuestions({ testCode: id, type: selectedQType });
      }
      checkPermissions();
    }
  }, [selectedQType, currentQuestion]);

  useEffect(() => {
    if (videoInterviewQuestions.length > 0) {
      setActiveStep(videoInterviewQuestions[0].id);
      setStartInterview(true);
    }
  }, [videoInterviewQuestions]);

  useEffect(() => {
    setsCurrentQuestion();
  }, [activeStep]);

  useEffect(() => {
    setIsLoading(false);
  }, [currentQuestion]);

  async function checkPermissions() {
    if (window.location.pathname === '/career-coach/evaluation') {
      return;
    }
    try {
      await getMediaStream();
      return () => releaseDevices();
    } catch (err) {
      setHasPermission(false);
      setIsLoading(false);

      // Handle specific errors
      if (
        err === 'no_specified_media_found' ||
        err === 'DevicesNotFoundError'
      ) {
        console.error('No devices found.');
      } else if (err === 'permission_denied') {
        console.error('Permissions denied for accessing media devices.');
      } else {
        console.error('An unexpected error occurred.');
      }
    }
  }

  // Function to handle the media stream (e.g., displaying it in a video element)
  function gotUserMedia(stream) {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.srcObject = stream;
      videoElement.onloadedmetadata = function (e) {
        videoElement.play();
      };
    } else {
      console.log('No video element found to display the stream.');
    }
  }

  useEffect(() => {
    if (startInterview && !hasPermission) {
      checkPermissions();
    }
    return () => {
      releaseDevices();
    };
  }, []);

  const streamCamVideo = async () => {
    if (window.location.pathname === '/career-coach/evaluation') {
      return;
    }
    await getMediaStream();
  };

  useEffect(() => {
    if (startInterview) {
      streamCamVideo();
    }
  }, [startInterview]);

  const releaseDevices = async () => {
    console.log('function runnnnnnn');
    if (previewStream) {
      previewStream.getTracks().forEach((track) => {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (previewStream) {
      // Additional step: Release microphone resources explicitly
      previewStream.getAudioTracks().forEach((track) => {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
    }
    // Ensure to clear any video elements using the stream (especially for Safari)

    // Optional: Revoke object URLs if created for blobs
    if (mediaBlobUrl) {
      URL.revokeObjectURL(mediaBlobUrl);
    }
    console.log('function runnnnnnn');

    return true;
  };

  useEffect(() => {
    if (isVideoInterviewCompleted) {
      releaseDevices();
      navigate('/career-coach/evaluation', { replace: true });
    }
  }, [isVideoInterviewCompleted]);

  useEffect(() => {
    setVideoBlobUrl(mediaBlobUrl);
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (resetRecorder) {
      clearBlobUrl();
      streamCamVideo();
    }
  }, [resetRecorder]);

  const handleStopRecording = async () => {
    setQuestionTime(null);
    stopRecording();
    setSimpleLoader(true);
    setTimeout(() => {
      setSimpleLoader(false);
    }, 400);
    setShowAgainVideo(false);
    await releaseDevices();
    return true;
  };

  const handleNext = () => {
    if (!videoBlobUrl) {
      alert('You must record your video');
      return;
    }
    // Retrieve the initial values of total and uploaded chunks
    let totChunk = parseInt(getLocalStorage('totChunk'));
    let uploadedChunks = parseInt(getLocalStorage('uploadedChunks'));

    // Check if totChunk and uploadedChunks are equal
    const checkChunks = () => {
      totChunk = parseInt(getLocalStorage('totChunk'));
      uploadedChunks = parseInt(getLocalStorage('uploadedChunks'));
      let progPercent = 0;
      if (uploadedChunks > 0) {
        progPercent = Math.ceil((uploadedChunks / totChunk) * 100);
      }
      setProgressBar(true);
      setProgress(progPercent);
      if (totChunk && uploadedChunks && totChunk <= uploadedChunks) {
        clearInterval(intervalId); // Clear the interval when chunks are equal
        handleSaveQuestion('', 'N'); // Trigger save question
        setProgress(0);
        setProgressBar(false);
        stopAll();
        clearBlobUrl();
      }
    };

    // Set an interval to check the chunks every 1 second
    const intervalId = setInterval(checkChunks, 500);
    setVideoBlobUrl(null);
    setIsSavingAnswers(true);
  };

  let isUploading = false;
  const getExtensionFromBlob = (mimeType) => {
    // Map MIME types to file extensions
    const mimeToExtension = {
      // Video MIME types
      'video/webm': 'webm',
      'video/mp4': 'mp4',
      'video/ogg': 'ogv',
      'video/x-matroska': 'mkv',
      'video/x-matroska;codecs=avc1,opus': 'mkv',
      'video/x-msvideo': 'avi',
      'video/quicktime': 'mov',
    };

    // Return the corresponding extension or fallback to an empty string if not found
    return mimeToExtension[mimeType] || 'mp4';
  };

  const handleSaveQuestion = async (mediaBlobUrl, beforeSubmit = '') => {
    let videoBlob = null;
    const formData = new FormData();
    formData.append('testCode', id);
    formData.append('questionId', currentQuestion?.id);
    formData.append('answerRd', answerRd);
    let videoSize = 0;

    if (mediaBlobUrl) {
      videoBlob = mediaBlobUrl;
      const ext = getExtensionFromBlob(mediaBlobUrl.type);
      // const type = mediaFormat.split(';')[0];
      // const ext = type.split('/')[1];
      const videoFile = new File([videoBlob], 'videoFile.' + ext, {
        type: mediaBlobUrl.type,
      });

      const chunkSize = convertMbsToBytes(4);

      videoSize = videoFile?.size;
      let totChunks = parseInt(getLocalStorage('totChunk'));
      let uploadedChunks = parseInt(getLocalStorage('uploadedChunks'));

      const chunk = videoFile;
      formData.append('file', chunk);
      formData.append('ext', ext);
      formData.append('totChunks', totChunks);
      formData.append('chunkSize', videoSize);
      formData.append('uploadedChunks', uploadedChunks);
      formData.append('videoSize', videoSize);
      formData.append('ansLang', language);
      formData.append('currentTime', getCurrentFormattedDate());
    }
    const startTime = performance.now();

    isUploading = true;
    let resp = null;
    if (mediaBlobUrl) {
      resp = await usefileUploader(
        'videoInterview/uploadChunkGuru',
        'post',
        fileUploadTokenHeaders(),
        formData,
      );
    } else {
      resp = await usefetch(
        'videoInterview/uploadChunkGuru',
        'post',
        headers(),
        {
          testCode: id,
          questionId: currentQuestion?.id,
          answerRd: answerRd,
          ansLang: language,
          qType: selectedQType,
        },
      );
    }

    if (resp?.data?.answerId) {
      const endTime = performance.now();
      const uploadDurationSeconds = (endTime - startTime) / 1000;

      // Calculate upload speed in KB/s
      const uploadSpeedKbps = videoSize / 1024 / uploadDurationSeconds;
      setUploadSpeed(uploadSpeedKbps);
      let upload = getLocalStorage('uploadedChunks');
      setLocalStorage('uploadedChunks', parseInt(upload) + 1);
      if (mediaBlobUrl) {
        setCurrentChunkUploded((prev) => prev + 1);
      }

      setAnswerId(resp?.data?.answerId);
      localStorage.setItem('answerId', resp?.data?.answerId);
      if (beforeSubmit === 'N') {
        setProgressBar(false);
        setResultId(resp?.data?.answerId);
        isUploading = false;
        var mediaStream = document.querySelector('video')?.srcObject;
        releaseDevices(mediaStream);
        setIsVideoInterviewCompleted(true);

        //complete interview api call remaining
        completeVideoInterview({ testCode: id }).then((resp) => {});
        setIsSavingAnswers(false);
        setIsLoading(false);
        setLoader(true);
      }
    }
    // }
  };

  const handleShare = async (data) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Interview Guru!',
          text: 'Just practiced my interview with Interview Guru by Rozee!',
          url: data.permalink,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  const setsCurrentQuestion = () => {
    if (!activeStep) return false;
    const cQ = videoInterviewQuestions.find(
      (question) => question.id === activeStep,
    );
    if (cQ) {
      setCurrentQuestion(cQ);
    }
    setToggle(true);
  };

  useEffect(() => {
    if (toggle) {
      console.log('generateRandomIntegerString', 'HITTTT');

      setAnswerRd(generateRandomIntegerString(7));
      setLocalStorage('uploadedChunks', 0);
      setLocalStorage('totChunk', 0);
      setHideQuestion(false);
      setProgress(0);
      setProgressBar(false);
    }
  }, [toggle]);

  const handleReset = () => {
    setActiveStep(0);
  };

  const getResult = async () => {
    if (resultId) {
      return usefetch('videoInterview/ansStatus', 'post', defaultHeaders(), {
        id: resultId,
      }).then(function (resp) {
        if (resp?.data?.analysis_status === 'done') {
          setSimpleLoader(false);
          setVideoAnalysis(resp?.data?.analysis);
          setVideoData(resp?.data);
          if (resp?.data?.countdown) {
            setCountdown(resp?.data?.countdown);
          }
          if (resp?.data?.countdown) {
            setRerecord(resp?.data?.reRecord);
          }
          if (!isEmpty(resp?.data?.ans_lang)) {
            setLanguage(resp?.data?.ans_lang);
          }
          if (!user?.seekerType) {
            localStorage.setItem('answerId', resultId);
            setTimeout(() => {
              setCompleteProfileDrawer(true);
            }, 5000);
          } else {
            setTimeout(() => {
              localStorage.setItem('answerId', resultId);
              setQualifyDrawer(resp?.data?.showContest === 'Y');
            }, 3000);
          }

          // if (user?.seekerType) {

          // }

          setCurrentQuestion(resp?.data?.question);
          setActiveStep(resp?.data?.question.id);
          setQType(resp?.data?.question?.type);
          setQuestionDrawer(false);
          releaseDevices();
          navigate('/career-coach/evaluation', { replace: true });
        }
        return resp;
      });
    }
  };

  useEffect(() => {
    let timeInterval = null;
    if (resultId) {
      const fetchResult = () => {
        getResult().then((resp) => {
          if (isEmpty(resp?.data)) {
            navigate('/career-coach/dashboard', { replace: true });
          } else {
            if (resp?.data?.analysis_status === 'done') {
              setLoader(false);
              if (intervalRef?.current) {
                clearInterval(intervalRef.current); // Clear interval if the status is 'done'
              }
            }
          }
        });
      };

      // Run the fetch once immediately (first attempt)
      fetchResult();

      // Set up interval for subsequent checks
      intervalRef.current = setInterval(fetchResult, 5000);

      // Cleanup on unmount or when resultId changes
      return () => {
        if (intervalRef?.current) {
          clearInterval(intervalRef.current);
        }
      };

      // run on first attempt
      getResult().then((resp) => {
        if (resp?.data?.analysis_status === 'done') {
          setLoader(false);
          clearInterval(timeInterval);
        }
      });
      // intervals
      timeInterval = setInterval(() => {
        getResult().then((resp) => {
          if (resp?.data?.analysis_status === 'done') {
            setLoader(false);
            clearInterval(timeInterval);
          }
        });
      }, 5000);
    }
    return () => clearInterval(timeInterval);
  }, [resultId]);

  const guruOrder = async (paymentId) => {
    const resp = await usefetch('career/guruOrder', 'POST', headers(),{
      paymentId: paymentId,
    });
    if (resp?.code == '11') {
      setPaymentData(resp?.data);
      setOrderId(() => resp?.data?.orderId);
      setInvoice(() => resp?.data?.invoice);
      setOrderAmount(() => resp?.data?.amount);
      setLocalStorage('OrderId', resp?.data?.orderId);
      setLocalStorage('OrderAmount', resp?.data?.amount);
      setLocalStorage('invoice', resp?.data?.invoice);
      setUpgradePopup(true);
      setGuruFlow(true);
    }
    return resp;
  };

  const payNow = async (orderid = '', cart_amount = '') => {
    const data = {
      orderId: orderid ? orderid : OrderId,
      cartAmount: cart_amount ? cart_amount : OrderPayment,
    };
    const resp = await usefetch('career/payOrder', 'POST', headers(), data);
    if (resp?.code == '11') {
      if (resp?.data?.token == 'undefined') {
        console.log(resp.data);
        setOrderId(resp?.data?.orderId);
      } else {
        setPaymentData(resp?.data);
        setOrderId(resp?.data?.orderId);
        setOrderAmount(resp?.data?.amount);
        setLocalStorage('OrderId', resp?.data?.orderId);
        setUpgradePopup(true);
      }
    }
  };

  return {
    classes,
    theme,
    getVideoInterviewQuestions,
    jobTitle,
    reRecord,
    isVideoInterviewCompleted,
    videoInterviewQuestions,
    setIsVideoInterviewCompleted,
    isLoadingInterviews,
    interviewsCount,
    getCurrentAnswer,
    isGettingQuestions,
    isLoading,
    isSavingAnswers,
    setIsLoading,
    countdown,
    activeStep,
    checkPermissions,
    handleNext,
    handleSaveQuestion,
    setCurrentQuestion,
    currentQuestion,
    handleReset,
    toggle,
    setToggle,
    startRecord,
    setStartRecord,
    questionTime,
    setQuestionTime,
    showAgainVideo,
    setShowAgainVideo,
    clearVideoInterview,
    isClearingInterview,
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
    hasPermission,
    setHasPermission,
    handleStopRecording,
    streamCamVideo,
    setSimpleLoader,
    simpleLoader,
    questionDrawer,
    setQuestionDrawer,
    evaluationDrawer,
    setEvaluationDrawer,
    setActiveStep,
    setStartInterview,
    selectedQuestions,
    releaseDevices,
    setTQuestion,
    selectedQType,
    setQType,
    videoAnalysis,
    paymentDrawer,
    setPaymentDrawer,
    completeProfileDrawer,
    setCompleteProfileDrawer,
    videoData,
    loader,
    language,
    setLanguage,
    setAnswerId,
    getResult,
    setResultId,
    setLoader,
    id,
    setId,
    stream,
    progressBar,
    setCountdown,
    progress,
    setProgress,
    mediaStreamRef,
    getInterviews,
    interviews,
    answerTestCode,
    hideQuestion,
    setHideQuestion,
    uploadSpeed,
    qualifyDrawer,
    setRerecord,
    setQualifyDrawer,
    interviewTypes,
    getInterviewsTypes,
    creditsUsed,
    isLoadingTypes,
    remainingCredits,
    stopAll,
    displayName,
    setDisplayNameCheck,
    showSuggestion,
    setShowSuggestion,
    handleShare,
    upgradePopup,
    setUpgradePopup,
    guruOrder,
    payNow,
    PaymentData,
    OrderPayment,
    guruFlow,
    paymentSuccessToggle,
    setPaymenttSuccessToggle,
    paymentRes,
    setPaymentRes,
    loadingMore,
    setLoadingMore,
    setOrderId,
    setOrderAmount,

  };
};
