import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Skeleton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChangeQuestion from '../ChangeQuestion';
import Avatars from '../../../../common/Avatar';
import { headers, usefetch } from '../../../../helpers/fetch';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetTopAnswers } from '../../../../services/career-coach/useGetTopAnswers';
import { isEmpty } from '../../../../helpers/common';
import { readCookie } from '../../../../helpers/CookieHelper';
import { useLogPayment } from '../../../../services/useLogPayment';
import { useAuthContext } from '../../../../Auth/useAuthContext';
import { CareerCoachInterviewContext } from '../../VideoInterview/CareerCoachInterviewContext';

export default function BestInterviews() {
  const navigate = useNavigate();
  const [changeQuestionDrawer, setChangeQuestionDrawer] = useState(false);
  const [data, setData] = useState({});
  const {
    getTopAnswers,
    topQuestions,
    isGettingTopAnswers,
    questionsCount
  } = useGetTopAnswers();
  const { qId } = useParams();

    const [page, setPage] = useState(1);          // Current page number
    const [loading, setLoading] = useState(false); // Loading state
    const [hasMore, setHasMore] = useState(true);

  const { logPayment } = useLogPayment();
  const { user } = useAuthContext();
  const {
    setId,
    setQType,
    setToggle,
    handleShare,
    setCurrentQuestion,
    setStartInterview,
    setCountdown,
    setRerecord,
    loadingMore,
    setLoadingMore,
  } = useContext(CareerCoachInterviewContext);

  useEffect(() => {
    if (!changeQuestionDrawer && isEmpty(topQuestions)) {
      getTopAnswers({ qId: qId });
    }
  }, [changeQuestionDrawer]);

  const loadMore = () => {
    setLoadingMore(true);
      const nextStart = topQuestions?.length;
      if (nextStart < Number(questionsCount)) {
          getTopAnswers({
              start: nextStart,
              qId: qId
          }).then((resp) => {
              setLoadingMore(false);
          });
      } else {
          setLoadingMore(false);
      }
  };

  return (
    <Stack p={'20px'}>
      <Stack
        pb={'18px'}
        sx={{ cursor: 'pointer', width: 'max-content' }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <Typography
          variant='title14Bold'
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            width: 'max-content',
          }}
        >
          <ArrowBackIcon
            sx={{ marginRight: 1.2, height: '20px', width: '20px' }}
          />
          Back
        </Typography>
      </Stack>

      <Typography
        onClick={(e) => {
          if (isGettingTopAnswers) {
            return;
          } else {
            e.stopPropagation();
            setChangeQuestionDrawer(true);
          }
        }}
        variant='title14Bold'
        sx={{
          fontWeight: '500',
          color: '#010103',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid #010103',
          height: '48px',
          px: '16px',
          borderRadius: '50px',
        }}
      >
        {isGettingTopAnswers && !loadingMore ? (
          <Skeleton animation='wave' height={20} width='100px' />
        ) : (
          'Change Question'
        )}{' '}
        <KeyboardArrowDownIcon />
      </Typography>

        {topQuestions?.map((topQuestion, i) => (
            <Stack key={i++}>

                <Typography
                    variant='title14Bold'
                    sx={{
                        p: '16px',
                        borderRadius: '18px',
                        fontWeight: '600',
                        mt: '8px',
                        background: '#EAF2FF',
                        border: '1px solid #256EF4',
                    }}
                >
                    {isGettingTopAnswers && !loadingMore ? (
                        <Skeleton animation='wave' height={20} width='100px' />
                    ) : (
                        topQuestion?.question?.question
                    )}
                </Typography>

                <Stack
                    mt={'32px'}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='title16Bold'
                        sx={{ fontWeight: '600', color: '#585858' }}
                    >
                        Top{' '}
                        {Number(topQuestion?.question?.answerCount) < 10
                            ? Number(topQuestion?.question?.answerCount) === 1
                                ? ' Answer'
                                : `${Number(topQuestion?.question?.answerCount)}`
                            : 10}
                    </Typography>
                    {isGettingTopAnswers && !loadingMore ? (
                        <Skeleton animation='wave' height={20} width='100px' />
                    ) : (
                        <Typography
                            variant='title14Bold'
                            sx={{
                                fontWeight: '700',
                                color: '#256EF4',
                                textDecoration: 'underline',
                            }}
                            onClick={() => {
                                logPayment({
                                    action: 'practiceQuestion',
                                    // answerId: answer?.answerId,
                                    userCookie: isEmpty(user)
                                        ? readCookie('userCookie')
                                        : undefined,
                                });
                                const careerCoachInterview = localStorage.getItem(
                                    'careerCoachInterview',
                                );

                                if (!isEmpty(careerCoachInterview) && !isEmpty(user)) {
                                    setCurrentQuestion(topQuestion?.question);
                                    setQType(topQuestion?.question?.type);
                                    setCountdown(topQuestion?.question?.countdown);
                                    setRerecord(topQuestion?.question?.reRecord);
                                    setId(careerCoachInterview);
                                    setStartInterview(true);
                                    localStorage.removeItem('answerId');
                                    setToggle(true);
                                    navigate(`/career-coach/interview/${careerCoachInterview}`, {
                                        replace: true,
                                    });
                                } else if (isEmpty(user)) {
                                    sessionStorage.setItem('viQues', JSON.stringify(topQuestion?.question));
                                    navigate('/career-coach/mobile');
                                }
                            }}
                        >
                            Practice & Win Rs. 100,000
                        </Typography>
                    )}
                </Stack>

                <Stack mt='15px'>
                    {isGettingTopAnswers && !loadingMore
                        ? [...Array(5)].map((k, i) => {
                            return (
                                <Stack
                                    py={'20px'}
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                                    key={i++}
                                >
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='flex-start'
                                    >
                                        <Skeleton variant='circular' height='44px' width='44px' />
                                        <Stack pl={'10px'}>
                                            <Skeleton
                                                width='222px'
                                                height='30px'
                                                style={{
                                                    borderRadius: '12px',
                                                }}
                                            />
                                            <Skeleton
                                                width='122px'
                                                height='20px'
                                                style={{
                                                    borderRadius: '12px',
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            );
                        })
                        : topQuestion?.question?.answers?.map((topAnswer, j) => {
                            return (
                                <Stack
                                    py={'20px'}
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                                    key={j++}
                                >
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='flex-start'
                                        sx={{
                                            '& .MuiAvatar-root': {
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                height: '44px',
                                                width: '44px',
                                            },
                                        }}
                                    >
                                        <Avatars userName={topAnswer?.name} />
                                        <Stack pl={'10px'}>
                                            <Typography
                                                variant='title14Bold'
                                                sx={{ fontWeight: '600' }}
                                            >
                                                {topAnswer?.name}
                                            </Typography>
                                            <Typography
                                                pt={'3px'}
                                                variant='title12Bold'
                                                sx={{ fontWeight: '500', color: '#ACACAC' }}
                                            >
                                                {topAnswer?.completedAt}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='flex-end'
                                    >
                                        <Typography
                                            sx={{
                                                border: '1px solid #E8B029',
                                                borderRadius: '50px',
                                                height: '38px',
                                                width: '38px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: '700',
                                                color: '#E8B029',
                                            }}
                                            variant='title16Bold'
                                        >
                                            {topAnswer?.avgVote}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ml: '6px',
                                                border: '1px solid #ACACAC',
                                                borderRadius: '50px',
                                                height: '38px',
                                                width: '38px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: '700',
                                                color: '#010103',
                                            }}
                                            variant='title16Bold'
                                            onClick={() => {
                                                window.location.href = topAnswer?.permalink;
                                            }}
                                        >
                                            <PlayArrowRoundedIcon />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            );
                        })}

                </Stack>

            </Stack>
        ))}

        {topQuestions?.length < Number(questionsCount) && (
            <Stack
            py={'18px'}
            width='100%'
            alignItems='center'
            justifyContent='center'
        >
            <Typography
                variant='title14Bold'
                sx={{
                    fontWeight: '600',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => loadMore()}
            >
                Load More{' '}
                {loadingMore && (
                    <CircularProgress
                        sx={{
                            height: '19px !important',
                            width: '19px !important',
                            ml: '8px',
                        }}
                    />
                )}
            </Typography>
        </Stack>)}

      {/* Change Question Drawer */}
      <ChangeQuestion
        from={'best-interview'}
        toggle={changeQuestionDrawer}
        setToggle={setChangeQuestionDrawer}
      />
    </Stack>
  );
}
